import React, { useState, useContext, useEffect, useRef, ReactNode } from "react";
import classNames from "classnames";
import { LoadingIndicatorInline } from "../loading_indicator/index";
import CategorySideBarMenu from "../category_sidebar_menu/index";
import SideBar, { IMenuItem } from "../sidebar/index";
import { CategoryMetadataFile } from "models/category";
import { adEngagePath } from "utils/constants";
import MoreBrandsMoreCategoriesModal from "../more_brands_more_categories";
import { scrollIntoView } from "seamless-scroll-polyfill";

import * as styles from "./styles.module.scss";

import {
  fetchCategoriesMetaData, fetchWomensWorldCupData,
  fetchWorldCupData
} from "utils/ad_engage";

import { MixPanelContext } from "context/mixpanel_context";
import {
  TopEventCreativeMetadata,
  TopEventCreativeByCategoryMetadata
} from "models/top_event_creative";
import { NewTopEventCreativeCard } from "../creative_card/index";
import { CategoryOverviewPageHeader, getSidebarMenuItems } from "./CategoryOverview";
import {Link} from "gatsby";

export interface WorldCupOverviewProps {
  pageHeader?: ReactNode;
  pageFooter?: ReactNode;
  amazonTnfEnglish?: boolean;
  amazonTnfSpanish?: boolean;
  headerTitle?: string;
  isWomens?: boolean;
}

const worldCupOrder = [
  "Final",
  "Third-Place Match",
  "Semifinals",
  "Quarterfinals",
  "Round of 16",
  "Group Stage"
];

const WorldCupOverview: React.FC<(WorldCupOverviewProps)> = (props) => {
  const { pageHeader, pageFooter, isWomens } = props;
  const [topAdsByCategory, setTopAdsByCategory] = useState<{ [key: string]: TopEventCreativeByCategoryMetadata }>(null);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [sideBarLoading, setSideBarLoading] = useState(true);
  const [categoryMetadata, setCategoryMetadata] = useState<CategoryMetadataFile>();
  const [showModal, setShowModal] = useState(false);
  const mixpanel = useContext(MixPanelContext);

  useEffect(() => {
    Promise.all([
      fetchCategoriesMetaData()
      .then((data) => { setCategoryMetadata(data) })
      .catch((err) => { console.log(err) }),
      (isWomens ? fetchWomensWorldCupData() : fetchWorldCupData())
      .then((data) => { setTopAdsByCategory(data.data); })
      .catch((err) => { console.log(err) })
    ]).finally(() => { 
      setSideBarLoading(false);
      setContentLoading(false);
     });
  }, []);

  const contentRef = useRef<null | HTMLDivElement>(null);
  // useEffect(() => {
  //   contentRef?.current && scrollIntoView(contentRef.current, { block: "nearest", inline: "start"});
  // }, [sideBarLoading, contentLoading])

  const renderContent = () => {
    if (contentLoading) {
      return (
        <div className={classNames("flex-fill-remainder", styles.loadingIndicatorContainer)}>
          <LoadingIndicatorInline width="64px" height="64px" />
        </div>
      )
    }

    return (
      <div className={classNames("flex-fill-remainder full-height-and-width-minus-navbar-and-sidebar")}>
        <div className={classNames("flex-col", styles.nflWeekCol)}>
          <div className={styles.nflWeekText}>Sorted by Performance</div>
        </div>
        <div className={"flex-row flex-wrap"}>
          { topAdsByCategory && worldCupOrder.map((key) => (
            <>
              { topAdsByCategory[key] && topAdsByCategory[key]["top_ads"] && topAdsByCategory[key]["top_ads"].length > 0 && (
                <div key={`${key}-container`} className={"flex-row flex-wrap"} style={{ margin: "10px 0", width: "100%" }}>
                  <div key={`${key}-category-name`} style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>
                    {topAdsByCategory[key]["name"]} <span style={{paddingLeft: "4px", opacity: 0.5, fontWeight: 'normal' }}>Top Performing Ads</span>
                  </div>
                  <div key={`${key}-category-see-more`} className={styles.seeMore}>
                    <Link to={"/contact-us"} >{"see more..."}
                    </Link>
                  </div>
                  <div key={`${key}-top-ads-container`} className={"flex-row flex-wrap"} style={{ width: "100%" }}>
                    {
                      topAdsByCategory[key] && topAdsByCategory[key]["top_ads"].slice(0,10).map((entity: TopEventCreativeMetadata, i: number) => {
                        return <NewTopEventCreativeCard
                          creative_id={entity.creative_id}
                          brand_name={entity.brand_name}
                          creative_title={entity.creative_title}
                          category_name={entity.category_name}
                          tv_ad_airings_count={entity.airing_count}
                          thumbnail_url={entity.thumbnail_url}
                          brand_image_url={entity.brand_image_url}
                          duration={entity.duration}
                          key={`${i}-creative-card`}
                          rank={i + 1}
                        />;
                      })
                    }
                  </div>
                </div>
              )}
            </>

          )) }
        </div>
      </div >
    )
  }

  return (
    <>
      <MoreBrandsMoreCategoriesModal open={showModal} onClose={() => setShowModal(false)} />
      { pageHeader || <CategoryOverviewPageHeader /> }
      <div className={classNames("fill-parent", styles.categoryContent)}>
      <div className={classNames(styles.worldCupHeaderContainer, styles.categoryOverviewHeader)}>

        <div className={classNames("flex-col")}>
          <div className={styles.categoryOverviewDisclaimerText}>
            Only EDO can report the real-time TV performance of every brand advertising in <span className={styles.categoryOverviewDisclaimerBoldText}>{ isWomens ? "the 2023 FIFA Women's World Cup." : "the 2022 FIFA World Cup." }</span>
          </div >
          <div className={styles.categoryOverviewDisclaimerText}>
            <span className={styles.categoryOverviewDisclaimerBoldText}>When your ad is on the line, </span>access to real-time customer engagement is the only thing that counts. Top brands rely on EDO's predictive outcomes data. 
          </div>
          <div className={styles.categoryOverviewDisclaimerText}>
            Want to learn more? Request your personalized brand demo today.
          </div>
        </div>
      </div>
        <div className={"flex-row"}>
          <SideBar>
            {sideBarLoading
              ? <div className={classNames("flex-col", styles.loadingIndicatorContainer)}>
                <LoadingIndicatorInline width="64px" height="64px" />
              </div>
              : <CategorySideBarMenu baseUrl={adEngagePath} menuItems={getSidebarMenuItems(categoryMetadata, setShowModal, mixpanel)} currentPath={isWomens ? "ad-engage/womens-world-cup" : "ad-engage/world-cup"} />
            }
          </SideBar>
          <div ref={contentRef} className={classNames("flex-fill-remainder full-height-and-width-minus-navbar-and-sidebar", styles.categoryOverview)}>
            {renderContent()}
          </div>
        </div>
        { pageFooter }
      </div>
    </>
  );
};

export default WorldCupOverview;
